const serializers = {
  types: {
    // figure: props => (
    //   <figure className="animation--fade-up animation">
    //     {props.node.image && (
    //       <img src={urlFor(props.node.image.asset)} alt={props.node.alt} />
    //     )}
    //     <div className="figure_text">
    //       <h2>{props.node.title}</h2>
    //       <p>{props.node.description}</p>
    //     </div>
    //   </figure>
    // ),
  },
}

export default serializers
